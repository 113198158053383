/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react'

import api from '../../../services/api'

import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import Skeleton from '../../atoms/Skeleton'

import TextField from '../../atoms/TextField'
import SelectField from '../../atoms/SelectField'

import schema from './validations'

import * as S from './styles'

const AddressSearch = forwardRef(
  (
    { documentType = 'bank-slip', isAddress = false, setPaymentInfoValid },
    ref
  ) => {
    const [showAddressFields, setShowAddressFields] = useState(false)
    const [errorCep, setErrorCep] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const cepRegex = /^\d{5}-\d{3}$/

    const {
      watch,
      register,
      getValues,
      setValue,
      handleSubmit,
      clearErrors,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(schema),
      defaultValues: {
        name: '',
        documentType: '',
        document: '',
        cep: '',
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        isAddress: isAddress,
      },
    })

    const onSubmit = (data) => {
      return { ...data }
    }

    useEffect(() => {
      setPaymentInfoValid(isValid)
    }, [isValid])

    const returnValidationToParent = () => {
      handleSubmit(onSubmit)()

      if (!isValid) {
        return false
      }

      const {
        name,
        documentType,
        document,
        cep,
        street,
        number,
        complement,
        neighborhood,
        city,
        state,
      } = getValues()

      return {
        name,
        document: document.replace(/\D/gi, ''),
        documentType,
        address: {
          cep,
          street,
          number,
          complement,
          neighborhood,
          city,
          state,
          name,
        },
      }
    }

    const documentTypeWatch = watch('documentType')

    const cepWatch = watch('cep')

    const isAddressWatch = watch('isAddress')

    const documentTypeCredCardList = [
      {
        value: 'cpf',
        label: 'Nacional (CPF)',
      },
      {
        value: 'cnpj',
        label: 'Nacional (CNPJ)',
      },
      {
        value: 'ssn',
        label: 'Estrangeiro (SSN)',
      },
      {
        value: 'vat',
        label: 'Estrangeiro (VAT)',
      },
      {
        value: 'passport',
        label: 'Estrangeiro (Tax Number)',
      },
    ]

    const documentTypeBankList = [
      {
        value: 'cpf',
        label: 'CPF',
      },
      {
        value: 'cnpj',
        label: 'CNPJ',
      },
    ]

    const clearFields = () => {
      setValue('state', '')
      setValue('city', '')
      setValue('street', '')
      setValue('neighborhood', '')
      clearErrors('state')
      clearErrors('city')
      clearErrors('street')
      clearErrors('neighborhood')
      setErrorCep(false)
    }

    const searchByZipCode = async (zipcode) => {
      setLoading(true)
      clearFields()

      const cleanZipcode = zipcode.replace(/[\s-]/g, '')
      try {
        const { status, data, error } = await api.get(
          `api/v1/cep/${cleanZipcode}`
        )

        if (!!error || status !== 200) {
          throw new Error(error)
        }

        setValue('state', data.state)
        setValue('city', data.city)
        setValue('street', data.street)
        setValue('neighborhood', data.neighborhood)
        setLoading(false)
      } catch (error) {
        clearFields()
        setLoading(false)
        setErrorCep(true)
      }
    }

    useEffect(() => {
      if (cepRegex.test(cepWatch)) {
        setShowAddressFields(true)
        searchByZipCode(cepWatch)
      } else {
        clearFields()
      }
    }, [cepWatch])

    useEffect(() => {
      setValue(
        'isAddress',
        isAddress &&
          (documentTypeWatch === 'cnpj' ||
            documentTypeWatch === 'cpf' ||
            documentTypeWatch === '')
      )
    }, [isAddress, documentTypeWatch])

    useImperativeHandle(ref, () => ({
      validateForm: returnValidationToParent,
    }))

    return (
      <S.Form>
        <S.Title>Dados para faturamento:</S.Title>
        <S.FormItem>
          <SelectField
            required
            fullWidth
            register={register}
            label="documentType"
            name="documentType"
            id="documentType"
            data-type={documentTypeWatch}
            placeholder="Documento de identificação"
            options={
              documentType == 'bank-slip'
                ? documentTypeBankList
                : documentTypeCredCardList
            }
            invalid={!!errors?.documentType?.message}
          />
          {!!errors?.documentType?.message && (
            <S.Warning invalid>{errors?.documentType?.message}</S.Warning>
          )}
        </S.FormItem>

        <TextField
          required
          type="text"
          name="document"
          label="document"
          register={register}
          placeholder="Número do documento"
          disabled={documentTypeWatch === ''}
          invalid={!!errors?.document?.message}
          helperText={errors?.document?.message}
          mask={documentTypeWatch === 'vat' ? null : documentTypeWatch}
          maxLength={documentTypeWatch === 'vat' && 50}
        />

        <TextField
          required
          type="text"
          name="name"
          label="name"
          register={register}
          placeholder={
            documentTypeWatch === 'cnpj' ? 'Razão social' : 'Nome completo'
          }
          invalid={!!errors?.name?.message}
          helperText={errors?.name?.message}
        />

        {isAddressWatch && (
          <>
            <TextField
              required
              type="text"
              name="cep"
              label="cep"
              register={register}
              invalid={!!errors?.cep?.message}
              helperText={errors?.cep?.message}
              placeholder="CEP"
              mask="cep"
            />

            <S.WrapperAnimation openAccordion={showAddressFields}>
              <S.WrapperFieldsAnimation isLoading={isLoading}>
                <TextField
                  disabled={!errorCep}
                  required
                  type="text"
                  name="street"
                  label="street"
                  register={register}
                  invalid={!!errors?.street?.message}
                  helperText={errors?.street?.message}
                  placeholder="Endereço"
                />

                <S.FormGroup>
                  <TextField
                    required
                    type="text"
                    name="number"
                    label="number"
                    register={register}
                    invalid={!!errors?.number?.message}
                    helperText={errors?.number?.message}
                    placeholder="Número"
                    mask="number"
                  />

                  <TextField
                    type="text"
                    name="complement"
                    label="complement"
                    register={register}
                    invalid={!!errors?.complement?.message}
                    helperText={errors?.complement?.message}
                    placeholder="Complemento"
                  />
                </S.FormGroup>

                <TextField
                  required
                  disabled={!errorCep}
                  type="text"
                  name="neighborhood"
                  label="neighborhood"
                  register={register}
                  invalid={!!errors?.neighborhood?.message}
                  helperText={errors?.neighborhood?.message}
                  placeholder="Bairro"
                />

                <TextField
                  required
                  disabled={!errorCep}
                  type="text"
                  name="city"
                  label="city"
                  register={register}
                  invalid={!!errors?.city?.message}
                  helperText={errors?.city?.message}
                  placeholder="Cidade"
                />

                <TextField
                  required
                  disabled={!errorCep}
                  type="text"
                  name="state"
                  label="state"
                  register={register}
                  invalid={!!errors?.state?.message}
                  helperText={errors?.state?.message}
                  placeholder="Estado"
                />
              </S.WrapperFieldsAnimation>

              <S.WrapperSkeleton isLoading={isLoading}>
                <Skeleton
                  variant="rectangular"
                  style={{ height: 56, marginBottom: 16 }}
                />
                <S.FormGroup>
                  <Skeleton
                    variant="rectangular"
                    style={{ height: 56, marginBottom: 16, width: '100%' }}
                  />
                  <Skeleton
                    variant="rectangular"
                    style={{ height: 56, marginBottom: 16, width: '100%' }}
                  />
                </S.FormGroup>
                <Skeleton
                  variant="rectangular"
                  style={{ height: 56, marginBottom: 16 }}
                />
                <Skeleton
                  variant="rectangular"
                  style={{ height: 56, marginBottom: 16 }}
                />
                <Skeleton
                  variant="rectangular"
                  style={{ height: 56, marginBottom: 16 }}
                />
              </S.WrapperSkeleton>
            </S.WrapperAnimation>
          </>
        )}
      </S.Form>
    )
  }
)

export default AddressSearch
